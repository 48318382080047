import * as React from "react";

import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import Container from "../components/ui-library/container/container";
import pageStyles from "../components/ui-library/design-tokens/common-page-content";
import { grid } from "../components/ui-library/design-tokens/grid";
import Heading from "../components/ui-library/text/heading";

const DisclaimerPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="Disclaimer" meta={[{ name: "robots", content: "noindex" }]} />

      <Container css={pageStyles}>
        <Heading
          component="h1"
          size="large"
          margin={{ desktop: { bottom: grid(4) }, mobile: { bottom: grid(3) } }}
        >
          Disclaimer
        </Heading>

        <Heading component="h2" margin={{ top: grid(4), bottom: grid(2) }}>
          No Investment Advice
        </Heading>
        <p>
          The information provided on this website does not constitute
          investment advice, financial advice, trading advice, or any other sort
          of advice and you should not treat any of the website's content as
          such. COINOMICS does not recommend that any cryptocurrency should be
          bought, sold, or held by you. Do conduct your own due diligence and
          consult your financial advisor before making any investment decisions.
        </p>

        <Heading component="h2" margin={{ top: grid(4), bottom: grid(2) }}>
          Accuracy of Information
        </Heading>
        <p>
          COINOMICS will strive to ensure accuracy of information listed on this
          website although it will not hold any responsibility for any missing
          or wrong information. COINOMICS provides all information as is. You
          understand that you are using any and all information available here
          at your own risk.
        </p>

        <Heading component="h2" margin={{ top: grid(4), bottom: grid(2) }}>
          Non Endorsement
        </Heading>
        <p>
          The appearance of third party advertisements and hyperlinks on
          COINOMICS does not constitute an endorsement, guarantee, warranty, or
          recommendation by COINOMICS. Do conduct your own due diligence before
          deciding to use any third party services.
        </p>

        <Heading component="h2" margin={{ top: grid(4), bottom: grid(2) }}>
          Affiliate Disclosure
        </Heading>
        <p>
          COINOMICS may receive compensation for affiliate links. This
          compensation may be in the form of money or services and could exist
          without any action from a site visitor. Should you perform activities
          in relation to an affiliate link, it is understood that some form of
          compensation might be made to COINOMICS. For example, if you click on
          an affiliate link, and sign up and trade on an exchange, COINOMICS may
          receive compensation.
        </p>
      </Container>
    </Layout>
  );
};

export default DisclaimerPage;
