import { CSSObject } from "@emotion/react";
import { grid } from "./grid";
import { Device } from "./media-queries";

const pageStyles: CSSObject = {
  p: {
    marginBottom: grid(2),

    [Device.Mobile]: {
      fontSize: 14,
    },
  },
};

export default pageStyles;
