import * as React from "react";

import { keyframes, CSSObject } from "@emotion/react";
import { darken } from "polished";
import { DeviceMargin, getMarginValue } from "../design-tokens/margin-helper";
import { Colors } from "../design-tokens/colors";
import { grid } from "../design-tokens/grid";
import {
  DeviceTextAlign,
  DeviceTextSize,
  getResponsiveSkeletonHeight,
  getResponsiveTextSkeletonAlign,
  TextAlignType,
} from "./text-helper";

export interface TextSkeletonProps {
  textSize?: DeviceTextSize | number;
  margin?: DeviceMargin;
  width?: number;
  lineSpan?: number;
  textAlign?: DeviceTextAlign | TextAlignType;
  className?: string;
}

const pulseKeyframes = keyframes({
  "0%": {
    backgroundColor: Colors.LightestGrey,
  },
  "50%": {
    backgroundColor: darken(0.1, Colors.LightGrey),
  },
  "100%": {
    backgroundColor: Colors.LightestGrey,
  },
});

const TextSkeleton: React.FC<TextSkeletonProps> = ({
  textSize,
  width,
  margin,
  textAlign,
  lineSpan,
  className,
}) => {
  const skeletonHeight = getResponsiveSkeletonHeight(textSize);

  const textAlignValue = getResponsiveTextSkeletonAlign(textAlign);

  const marginValue = getMarginValue(margin);

  const lineArray = new Array(lineSpan || 1).fill(null);
  lineArray.forEach((_, index) => (lineArray[index] = index));

  const styles: CSSObject[] = [
    {
      maxWidth: "100%",
      width: width ? `${width}px` : "300px",
      flexGrow: 0,
      flexShrink: 0,
      borderRadius: grid(4),
      animation: `${pulseKeyframes} 2s linear infinite`,
    },
    skeletonHeight,
    marginValue,
    textAlignValue,
  ];

  return (
    <>
      {lineArray.map((line) => (
        <div key={line} css={styles} className={className} />
      ))}
    </>
  );
};

export default TextSkeleton;
